

export default {
  name: 'HomePageSteps',

  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    steps: [],
  }),

  computed: {
    getHeader () {
      return this.api?.children.find(item => item?.componentType === 'header') || null
    },

    getLabel () {
      return this.getHeader?.children.find(item => item?.componentType === 'h1')?.data?.text || ''
    },
  },

  created () {
    const separator = { data: { title: 'separator' } }

    this.steps = JSON.parse(JSON.stringify(this.api?.children.find(item => item?.componentType === 'steps')?.children))

    for (let i = 1; i <= 5; i += 2) {
      this.steps.splice(i, 0, separator)
    }
  },
}
